import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SuccessfulOrderBanner from "../sections/order/successful-order-banner"
import Container from "@material-ui/core/Container"

import { useIntl } from "gatsby-plugin-react-intl"

const SuccessfulOrderPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        lang={intl.locale}
        description=""
        title={intl.formatMessage({
          id: "successfulOrderBanner_title",
        })}
      />
      <Container>
        <section className="successful-order-banner">
          <SuccessfulOrderBanner></SuccessfulOrderBanner>
        </section>
      </Container>
    </Layout>
  )
}

export default SuccessfulOrderPage
